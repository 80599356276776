/* global tumarket */

import * as $ from "jquery";
import "bootstrap/js/dist/dropdown";
import tumGlobal from "./global.js";
import { initTuPopover } from "./global/popover";
import { toggleReadmore } from "./global/readmore";
import { addEventDelegate } from "./global/dom";

function initEditMenu() {
  //функция, которая вызывается при клике на ридмор (атрибут data-callback)
  tumarket.callbacks.updateEditMenu = el => {

    //подставляет в ридмор html из data-contenttarget, если он есть и если ридмор пустой
    let $container = $(el).closest('.tum-readmore-wrap').find('.tum-readmore-0');
    let content = $(el).attr('data-contenttarget');
    if (content && !$container.html()) {
      $container.html($(content).html());

      //подключаем popover
      initTuPopover($container);
    }

    //скрывает другие ридморы в меню
    let $readmores = $('.tu-editMenu .tum-readmore-toggle').not(el);
    $readmores.toArray().forEach(e => toggleReadmore(e, false, true));

    //обновляет положение меню на случай, если изменилась ширина
    $('.tu-editMenuToggle').dropdown('update');
  };

  //открытие меню редактирования по кнопке с классом
  addEventDelegate(document, 'click', '.menu-openEditMenu', () => {
    let mob = tumGlobal.isMob();
    let shown = $(mob ? '.mob-menu' : '.desktop-menu').hasClass('show');

    function toggleMenu() {
      return import("./MainMenu.js").then(({tum_mainMenu}) => tum_mainMenu.toggleMenu());
    }

    (shown ? toggleMenu() : Promise.resolve())
      .then(() => {
        $('.tu-editMenuToggle').dropdown('toggle');
        tumGlobal.scrollUp();
        tumGlobal.highlight($('.tu-editMenu .dropdown-menu'));
      });
  });
}

export { initEditMenu };

